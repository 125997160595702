.App {
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: #d9b8f9;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.center-text {
  margin-bottom: 20px; /* Add some space between the text and the GIF */
}
.center-text {
  margin-bottom: 20px; /* Add some space between the text and the GIF */
}
.site-heading {
  font-family: monospace;
}