/* .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
*/
.gif-container {
    display: flex;
    justify-content: center;
  }
  
  .gif-container img {
    max-width: 70%;
    max-height: 70%;
  }
  